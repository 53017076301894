import type { IUser } from 'entities/user'

import { ConnectForm } from '../ConnectForm/ConnectForm'
import cls from '../AffirmationForm/AffirmationForm.module.scss'

type CheckboxesMessengers = {
  user: IUser
  modalId?: string
  onChecked: (e: any, id: string) => void
}

export const CheckboxesMessengers = (props: CheckboxesMessengers) => {
  const { user, modalId, onChecked } = props

  return (
    <ConnectForm>
      {({
        // @ts-ignore
        register,
      }) => (
        <>
          <h4 className={cls['form__title-mess']}>
            Выберите мессенджер для обратной связи
          </h4>
          <div className={cls.form__messengers}>
            <div className={cls.checkboxRect}>
              <input
                {...register('whatsapp')}
                checked={user.accepted.whatsapp}
                onChange={(e) => onChecked(e, 'whatsapp')}
                id={'whatsapp' + modalId}
                type="checkbox"
                name="whatsapp"
              />
              <label htmlFor={'whatsapp' + modalId}>Whatsapp</label>
            </div>
            <div className={cls.checkboxRect}>
              <input
                {...register('telegram')}
                checked={user.accepted.telegram}
                onChange={(e) => onChecked(e, 'telegram')}
                id={'telegram' + modalId}
                type="checkbox"
                name="telegram"
              />
              <label htmlFor={'telegram' + modalId}>Telegram</label>
            </div>
            <div className={cls.checkboxRect}>
              <input
                {...register('viber')}
                checked={user.accepted.viber}
                onChange={(e) => onChecked(e, 'viber')}
                id={'viber' + modalId}
                type="checkbox"
                name="viber"
              />
              <label htmlFor={'viber' + modalId}>Viber</label>
            </div>
          </div>
        </>
      )}
    </ConnectForm>
  )
}
