import cl from 'classnames'
import { SubmitAffirmationModal } from 'features/affirmation'
import InvestPlatformImg from 'shared/assets/images/refund-deposit/InvestPlatformImg.webp'
import cls from './RefundDeposit.module.scss'

export const RefundDeposit = () => {
  return (
    <section className={cl('flex__col_center', cls.page)}>
      <div className={cl(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <div className={cls.card}>
            <h3 className={cls.card__title}>
              Полное возмещение депозита за счет государства в случае сбоя
            </h3>

            <p className={cls.card__desc}>
              Инвестируя Вы гарантировано сохраняете вложенную Вами сумму (Закон
              от 7 июля 2006 года № 169-III)
            </p>

            <p className={cls.card__desc}>
              Если возникнет кризис на бирже или в вашей стране случится
              инфляция, вы получите свои сбережения назад в долларовом
              эквиваленте.
            </p>
          </div>

          <SubmitAffirmationModal
            btnContent="Оставить заявку"
            btnProps={{ variant: 'default' }}
          />
        </div>

        <div className={cls.right_block}>
          <img src={InvestPlatformImg} alt="InvestPlatformImg" />
        </div>
      </div>
    </section>
  )
}
