import useAppContext from 'app/store/useAppContext'
import { setIsPrivacyPolicyModal } from 'app/store/actions'
import { Modal } from 'shared/ui/modal'
import { PrivacyPolicyText } from '../PrivacyPolicyText/PrivacyPolicyText'

const customModalStyles = {
  overlay: {
    zIndex: 1,
  },
  content: {
    zIndex: 2,
    maxHeight: '100vh',
    overflow: 'auto',
  },
}
const customMobileSmallModalStyles = {
  overlay: {
    zIndex: 1,
  },
  content: {
    zIndex: 2,
    width: '100vw',
    height: `calc(100vh - 100px)`,
    margin: '20px 0 0 0',
    overflow: 'auto',
  },
}

export const PrivacyPolicyModal = () => {
  const {
    store: {
      isMobileSmall,
      modals: { isPrivacyPolicyModal },
    },
    dispatch,
  } = useAppContext()

  const closeModal = () => {
    dispatch(setIsPrivacyPolicyModal(false))
  }

  return (
    <Modal
      style={isMobileSmall ? customMobileSmallModalStyles : customModalStyles}
      isOpen={isPrivacyPolicyModal}
      isCloseIcon={!isMobileSmall}
      onRequestClose={closeModal}
      content={<PrivacyPolicyText />}
    />
  )
}
