import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import useAppContext from 'app/store/useAppContext'
import { setIsAcceptCookiesModal } from 'app/store/actions'
import { ROUTES } from 'shared/config/routes'
import { CookiesService } from 'shared/lib'

export default function useIsAcceptedCookies() {
  const { pathname } = useLocation()
  const { dispatch } = useAppContext()

  const isRegisteredPage = pathname === ROUTES.REGISTERED

  const isAcceptedCookies =
    CookiesService.get({ name: 'accept_cookies' }) === 'accepted'

  const openModal = () => {
    dispatch(setIsAcceptCookiesModal(true))
  }

  useEffect(() => {
    if (!isAcceptedCookies && !isRegisteredPage) {
      openModal()
    }
  }, [isAcceptedCookies, isRegisteredPage])
}
