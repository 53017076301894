import cl from 'classnames'
import ClockImg from 'shared/assets/images/open-account/ClockImg.webp'
import cls from './OpenAccount.module.scss'

export const OpenAccount = () => {
  return (
    <section className={cl('flex__col_center', cls.page)}>
      <div className={cl(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <img src={ClockImg} alt="ClockImg" />
        </div>

        <div className={cls.right_block}>
          <div className={cls.card}>
            <h3 className={cls.card__title}>
              Быстрое открытие счета и консультация наставника
            </h3>

            <p className={cls.card__desc}>
              Инвестировать может каждый человек старше 21-го года. Вам не
              требуется опыт в торговле на бирже - мы научим Вас всему сами.
            </p>

            <p className={cls.card__desc}>
              После открытия инвест-счета с Вами свяжется Ваш куратор. Он
              передаст инструкции для старта и обсудит стратегию безопасного
              заработка.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
