import { setIsCookiesModal, setIsPrivacyPolicyModal } from 'app/store/actions'
import type { IUser } from 'entities/user'

import { ConnectForm } from '../ConnectForm/ConnectForm'
import cls from '../AffirmationForm/AffirmationForm.module.scss'

type CheckboxPoliticsProps = {
  user: IUser
  onChecked: (e: any, id: string) => void
  dispatch: React.Dispatch<any>
}

export const CheckboxPolitics = (props: CheckboxPoliticsProps) => {
  const { user, onChecked, dispatch } = props

  const openCookiesModal = () => {
    dispatch(setIsCookiesModal(true))
  }
  const openPrivacyPolicyModal = () => {
    dispatch(setIsPrivacyPolicyModal(true))
  }

  return (
    <ConnectForm>
      {({
        // @ts-ignore
        register,
      }) => (
        <label className={cls.checkSmallLabel} htmlFor="politics">
          <input
            {...register('isPolitics')}
            checked={user.accepted.isPolitics}
            onChange={(e) => onChecked(e, 'isPolitics')}
            className={cls.checkSmall}
            type="checkbox"
          />
          <span className={cls.checkSmallLabelText}>
            <>
              Я согласен с{' '}
              <span onClick={openPrivacyPolicyModal}>
                политикой конфиденциальности
              </span>{' '}
              и{' '}
              <span onClick={openCookiesModal}>
                даю согласие на обработку моих персональных данных
              </span>
            </>
          </span>
        </label>
      )}
    </ConnectForm>
  )
}
