import classNames from 'classnames'
import PeopleImg from 'shared/assets/images/choose-us/PeopleImg.webp'
import cls from './ChooseUs.module.scss'

export const ChooseUs = () => {
  return (
    <section className={classNames('flex__col_center', cls.page)}>
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <img src={PeopleImg} alt="PeopleImg" />
        </div>

        <div className={cls.right_block}>
          <div className={cls.card}>
            <h3 className={cls.card__title}>
              Более 400 000 клиентов по всему миру выбирают нас
            </h3>

            <p className={cls.card__desc}>
              Наша передовая технология FREEDOM.AI анализирует сделки и динамику
              курсов ценных бумаг на бирже за счет сложного квантового
              компьютера.
            </p>

            <p className={cls.card__desc}>
              Эти данные помогают добиваться результата в 73,9% сделок - в
              долгосрочной перспективе каждый инвестор выходит в плюс.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
