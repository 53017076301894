import cl from 'classnames'
import useAppContext from 'app/store/useAppContext'
import { SubmitAffirmationModal } from 'features/affirmation'
import cls from './HovInvest.module.scss'

export const HovInvest = () => {
  const {
    store: { isMobile500 },
  } = useAppContext()

  return (
    <section className={cl('flex__col_center', cls.page)} id="hovInvest">
      <div className={cl(cls.page__container, 'container__desktop')}>
        <div className={cls.content}>
          <h3 className={cls.title}>Как инвестировать в FREEDOM FINANCE?</h3>

          <div className={cls.cards}>
            <div className={cls.card}>
              <div className={cl(cls.card__num, cls.num_active)}>
                <p>1</p>
              </div>
              <p className={cls.card__desc}>
                Заполните форму заявки на открытие инвест-счета
              </p>
            </div>

            <div className={cls.card}>
              <div className={cls.card__num}>
                <p>2</p>
              </div>
              <p className={cls.card__desc}>
                Дождитесь звонка помощника по телефону
              </p>
            </div>

            <div className={cls.card}>
              <div className={cls.card__num}>
                <p>3</p>
              </div>
              <p className={cls.card__desc}>
                Пополните счет на $150 и получайте пассивный доход
              </p>
            </div>
          </div>

          {isMobile500 && (
            <SubmitAffirmationModal
              btnContent="открыть личный счет"
              btnProps={{ variant: 'default' }}
            />
          )}
        </div>
      </div>
    </section>
  )
}
