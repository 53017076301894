import cl from 'classnames'
import useAppContext from 'app/store/useAppContext'
import { SubmitAffirmationModal } from 'features/affirmation'
import HeroImg from 'shared/assets/images/promotion/HeroImg.webp'
import { Rating } from 'shared/ui/rating/Rating'
import { promotionData } from '../../../model/promotion'
import cls from './Promotion.module.scss'

export const Promotion = () => {
  const {
    store: { isMobile500 },
  } = useAppContext()

  return (
    <section className={cl('flex__col_center', cls.page)}>
      <div className={cl('container__desktop', cls.page__container)}>
        <div className={cls.left_block}>
          <div className={cls.content}>
            <h1 className={cls.title}>
              <span>ИНВЕСТИРУЙТЕ</span>
              <span>от {promotionData.investFromSum}</span>
              <span>И ПОЛУЧАЙТЕ</span>
              <span>
                ОТ <span>{promotionData.receiveFromSum}</span>
              </span>
              <span>КАЖДЫЙ МЕСЯЦ</span>
            </h1>

            <p className={cls.desc}>
              Платформа для заработка с помощью торгового робота FREEDOM.AI
            </p>

            <p className={cls.desc}>Хеджируйте позиции при помощи опционов</p>

            <SubmitAffirmationModal
              btnContent={
                isMobile500 ? 'начать инвестировать' : 'Оставить заявку'
              }
              btnProps={{ variant: 'default' }}
            />

            <Rating className={cls.rating} />
          </div>
        </div>
        <div className={cls.right_block}>
          <img src={HeroImg} alt="HeroImg" />
        </div>
      </div>
    </section>
  )
}
