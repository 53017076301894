import { Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import Header from 'widgets/header'
import Footer from 'widgets/footer'
import { useSetUserApiData, useSetUserSearchParams } from 'entities/user'
import { useSetIsMobile } from 'features/mobile'
import { AcceptCookieModal } from 'features/cookie'
import { useIsMobile } from 'features/mobile'
import { GiftModal, useShowGiftModal } from 'features/gift-modal'
import { ROUTES } from 'shared/config/routes'

export const MainLayout = () => {
  const { isMobileModal, isSomeModalsOpened } = useIsMobile()

  const { pathname } = useLocation()
  const isRegisteredPage = pathname === ROUTES.REGISTERED

  useSetIsMobile()
  useSetUserSearchParams()
  useSetUserApiData()
  useShowGiftModal()

  return (
    <div className="app">
      <Header />
      <main
        className={classNames(
          'main',
          isMobileModal && isSomeModalsOpened ? 'main--fixed' : null,
        )}
      >
        <Outlet />
      </main>

      {!isRegisteredPage && <Footer />}

      <AcceptCookieModal />
      <GiftModal />
    </div>
  )
}
