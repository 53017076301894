import cl from 'classnames'

import type { IUser } from 'entities/user'

import { ConnectForm } from '../ConnectForm/ConnectForm'
import cls from '../AffirmationForm/AffirmationForm.module.scss'

type CheckboxAgeProps = {
  user: IUser
  onChecked: (e: any, id: string) => void
}

export const CheckboxAge = (props: CheckboxAgeProps) => {
  const { user, onChecked } = props

  return (
    <ConnectForm>
      {({
        // @ts-ignore
        register,
      }) => (
        <label className={cls.checkSmallLabel} htmlFor="age">
          <input
            {...register('isAge')}
            checked={user.accepted.isAge}
            onChange={(e) => onChecked(e, 'isAge')}
            className={cls.checkSmall}
            type="checkbox"
          />
          <span
            className={cl(
              cls.checkSmallLabelText,
              cls.checkSmallLabelText__bold,
            )}
          >
            Я подтверждаю, что мне больше 21-го года
          </span>
        </label>
      )}
    </ConnectForm>
  )
}
