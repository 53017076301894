import { useLocation } from 'react-router-dom'
import { setIsAcceptCookiesModal, setIsCookiesModal } from 'app/store/actions'
import useAppContext from 'app/store/useAppContext'
import { ROUTES } from 'shared/config/routes'
import { Button } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'
import { CookiesService } from 'shared/lib'
import useIsAcceptedCookies from 'features/cookie/lib/hooks/useIsAcceptedCookies'
import { CookieDescription } from '../CookieDescription/CookieDescription'
import { CookiesPolicyModal } from '../CookiesPolicyModal/CookiesPolicyModal'

import styles from './AcceptCookieModal.module.scss'

const customCookieModalStyles = {
  overlay: {
    alignItems: 'flex-end',
  },
  content: {
    width: '100%',
    margin: 0,
    paddingTop: 0,
    backgroundColor: '#E6E6EA',
  },
}

export const AcceptCookieModal = () => {
  useIsAcceptedCookies()

  const {
    store: {
      isMobile,
      modals: { isCookiesModal, isAcceptCookiesModal },
    },
    dispatch,
  } = useAppContext()

  const { pathname } = useLocation()
  const isRegisteredPage = pathname === ROUTES.REGISTERED

  const openModal = (e: any) => {
    e.preventDefault()
    dispatch(setIsCookiesModal(true))
  }
  const closeModal = () => {
    dispatch(setIsCookiesModal(false))
  }
  const onAcceptCookies = (e: any) => {
    CookiesService.set({ name: 'accept_cookies', token: 'accepted' })

    dispatch(setIsAcceptCookiesModal(false))
  }

  return (
    <Modal
      isOpen={isAcceptCookiesModal && !isRegisteredPage}
      onRequestClose={closeModal}
      style={customCookieModalStyles}
      isCloseIcon={false}
      shouldCloseOnOverlayClick={false}
      content={
        <div className={styles.modal__content}>
          <CookieDescription
            isMobile={isMobile}
            className={styles.modal__text}
          />
          <div className={styles.modal__btns}>
            <Button onClick={onAcceptCookies}>Принимаю</Button>
            <Button onClick={openModal}>Политика Cookies</Button>
          </div>
          <CookiesPolicyModal />
        </div>
      }
    />
  )
}
