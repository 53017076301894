import useAppContext from 'app/store/useAppContext'
import { PrivacyPolicyModal } from 'features/privacy-policy'
import { CookiesPolicyModal } from 'features/cookie'
import { setIsCookiesModal, setIsPrivacyPolicyModal } from 'app/store/actions'
import Layout from 'widgets/_layout'
import { Logo } from 'shared/ui/logo'

import styles from './Footer.module.scss'

export default function Footer() {
  const { dispatch } = useAppContext()

  const openAcceptCookiesModal = (e: any) => {
    e.preventDefault()
    dispatch(setIsCookiesModal(true))
  }
  const openPrivacyPolicyModal = (e: any) => {
    e.preventDefault()
    dispatch(setIsPrivacyPolicyModal(true))
  }

  return (
    <footer className={styles.footer}>
      <Layout className={styles.layout}>
        <Logo />
        <div>
          <a href="/" onClick={openPrivacyPolicyModal}>
            Политика конфиденциальности
          </a>
          <a href="/" onClick={openAcceptCookiesModal}>
            Политика Cookies
          </a>
        </div>
      </Layout>
      <PrivacyPolicyModal />
      <CookiesPolicyModal />
    </footer>
  )
}
