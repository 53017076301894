import styles from './PrivacyPolicyText.module.scss'

export const PrivacyPolicyText = () => {
  return (
    <p className={styles.text}>
      {`
Политика конфиденциальности
1. Общие положения
  \u2022 1.1. Настоящая Политика конфиденциальности описывает установленный АО «Фридом Инвест» (далее - Общество) порядок обработки персональных данных, собранных с помощью мобильного приложения (далее - приложение), и связанных с ним услуг и инструментов в приложении. Общество обрабатывает персональные данные пользователей Сайта исключительно в рамках требований Закон от 21 мая 2013 года № 94-V «О персональных данных и их защите», а также международных ратифицированных договоров. Политика конфиденциальности (далее - Политика) разработана в соответствии с их нормами.
  \u2022 1.2. Настоящая Политика- официальный документ приложения, который определяет и регулирует порядок работы с информацией и обработки персональных денных физических и юридических лиц, обращающихся за услугами предоставляемые приложением. Политика предназначена для обеспечения надлежащей защиты информации о пользователях, в том числе их персональных данных, от несанкционированного доступа и разглашения.
  \u2022 1.3. Политика, а также другая официальная документация Сайта и действующее законодательство регулируют отношения, связанные со сбором, хранением, распространением и защитой информации о пользователях Сайта. В случае наличия противоречий между настоящей Политикой и иными официальными документами Сайта применению подлежит настоящая Политика.
  \u2022 1.4. В настоящей Политике используются следующие термины:
      \u2022 - Администрация сайта www.ffin.kz (далее – Администрация сайта) – уполномоченные работники на управления сайтом, действующие от имени Общества, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
      \u2022 - Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
      \u2022 - Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
      \u2022 - Конфиденциальность персональных данных – обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания
      \u2022 - Пользователь сайта www.ffin.kz (далее ‑ Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее сайт www.ffin.kz.
      \u2022 - Cookies - небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
      \u2022 - IP-адрес - уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
  \u2022 1.5. Регистрируясь и используя Сайт, Пользователь соглашается с условиями Политики. В случае если Пользователь несогласен с условиями Политики использование Сайта должно быть немедленно прекращено.
  \u2022 1.6. Администрация Сайта не проверяет достоверность информации о пользователях, которую они оставляют на Сайте, за исключением случаев, когда такая проверка необходима в целях исполнения Администрацией Сайта обязательств перед пользователем.
  \u2022 1.7. Пользователи должны знать, что при переходе на некоторые ссылки, размещённые на Сайте, они могут быть перенаправлены к сайтам (приложениям и т.д.) других компаний за пределы хостингового пространства Общества («Facebook», «google+», «ВКонтакте), где информация о Пользователях собирается вне прямого контроля Обществом и Администрации Сайта. В таком случае Политики конфиденциальности сайтов и/или приложений третьих лиц будут регулировать порядок обработки информации, полученной от пользователей этими третьими лицами.
  \u2022 1.8. Если Пользователь решил войти на сайт, используя службу аутентификации стороннего оператора, например информацию Facebook, Общество может получить дополнительный профиль, или другую информацию, доступ к которой предоставлен таким третьим лицом.
2. Порядок действия Политики конфиденциальности и внесения в нее изменений
  \u2022 2.1. Политика вступает в силу на неограниченный срок с момента его утверждения Советом директоров Общества. Действующая Политика признается недействительной после ее замены новой Политикой. Замена Политики осуществляется путем публикации ее новой редакции на Сайте.
  \u2022 2.2. Действующая редакция Политики, являющаяся публичным документом, доступна любому пользователю сети Интернет при переходе по ссылке www.ffin.kz. Администрация Сайта вправе вносить изменения в Политику, которые не противоречат законодательству. Размещение на Сайте новой редакции Политики после ее утверждения является уведомлением пользователей Сайта о внесенных в Политику изменениях.
3. Условия и цели обработки персональных данных
  \u2022 3.1. Администрация Сайта осуществляет обработку персональных данных пользователя в целях исполнения условий Соглашения между Администрацией Сайта и Пользователем на оказание услуг по использованию Сайта (Публичная оферта).
  \u2022 3.2. Оказывая свои услуги, Администрация Сайта, считает, что Пользователь:
      \u2022 - обладает всеми необходимыми правами, позволяющими ему осуществлять регистрацию и использовать настоящий Сайт;
      \u2022 - осознает, что информация на Сайте, размещаемая Пользователем о себе, может становиться доступной для третьих лиц, привлеченных Администрацией Сайта для исполнения обязательств.
  \u2022 3.3. Для оказания своих услуг Общество может использовать информацию, которую Общество собирает и размещает для следующих целей:
      \u2022 - обеспечения обслуживания клиентов Общества, в том числе для создания и управления учетными записями Пользователей, решения технических задач и доступа к различным функциям Сайта;
      \u2022 - адаптации предложений и опыта, в том числе рекламы на своих сервисах или сервисах третьих лиц;
      \u2022 - контроля общей и индивидуальной активности Пользователей, а также для управления трафиком на Сайте;
      \u2022 - связи с Пользователями, в том числе по вопросам сервиса, обслуживания клиентов или разрешенных маркетинговых коммуникаций через любые доступные каналы связи;
      \u2022 - проведения аналитической активности с целью улучшения клиентского сервиса Общества.
Общество может сохранять информацию, которую собирает и получает на своем Сайте для выполнении деловых целей Общества.
4. Состав персональных данных
  \u2022 4.1. Персональные данные Пользователей включают в себя:
      \u2022 4.1.1. уникальный идентификатор — адрес электронной почты;
      \u2022 4.1.2. дополнительные данные: имя, дата рождения, номер мобильного телефона, адрес доставки и другие.
      \u2022 4.1.3. дополнительно предоставляемые Пользователями по запросу Администрации Сайта в целях исполнения Администрацией Сайта обязательств перед Пользователями, вытекающих из условий Соглашения Пользователем. Администрация Сайта вправе, в частности, запросить у Пользователя копию документа, удостоверяющего личность, либо иного документа, содержащего имя, фамилию, фотографию Пользователя, а также иную дополнительную информацию, которая, по усмотрению Администрации Сайта, будет являться необходимой и достаточной для идентификации такого Пользователя и позволит исключить злоупотребления и нарушения прав третьих лиц.
  \u2022 4.2. Иная информация о Пользователях, обрабатываемая Администрацией Сайта - Администрация Сайта обрабатывает также иную информацию о Пользователях, которая включает в себя:
      \u2022 4.2.1. стандартные данные, автоматически получаемые сервером при доступе к Сайту и последующих действиях Пользователя (IP-адрес хоста, вид операционной системы пользователя, страницы Сайта, посещаемые пользователем, а также файлы Cookie);
      \u2022 4.2.2. информация, автоматически получаемая при доступе к Сайту с использованием закладок (cookies);
      \u2022 4.2.3. информация, полученная в результате действий Пользователя на Сайте;
      \u2022 4.2.4. информация, необходимая для идентификации Пользователя.
5. Обработка информации о пользователях
  \u2022 5.1. Обработка персональных данных осуществляется на основе принципов:
      \u2022 - законности целей и способов обработки персональных данных;
      \u2022 - добросовестности;
      \u2022 - соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных, а также полномочиям Администрации Сайта;
      \u2022 - соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных.
  \u2022 5.2. Сбор персональных данных.
      \u2022 - 5.2.1. Сбор персональных данных Пользователя осуществляется на Сайте при регистрации, а также в дальнейшем при внесении Пользователем по своей инициативе дополнительных сведений в инструментарии Сайта.
  \u2022 5.3. Хранение и использование персональных данных.
      \u2022 5.3.1. Персональные данные Пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда ручная обработка персональных данных необходима в связи с исполнением требований законодательства.
  \u2022 5.4. Передача персональных данных.
      \u2022 5.4.1. Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящей Политикой и условиями Соглашения с Пользователем (Публичная оферта).
      \u2022 5.4.2. При указании пользователем или при наличии согласия пользователя возможна передача персональных данных Пользователя третьим лицам-контрагентам Администрации Сайта с условием принятия такими контрагентами обязательств по обеспечению конфиденциальности полученной информации, в частности, при использовании приложений.
      \u2022 5.4.3. Приложения, используемые Пользователями на Сайте, размещаются и поддерживаются третьими лицами (разработчиками), которые действуют независимо от Администрации Сайта и не выступают от имени или по поручению Администрации Сайта. Пользователи обязаны самостоятельно ознакомиться с правилами оказания услуг и политикой защиты персональных данных таких третьих лиц (разработчиков) до начала использования соответствующих приложений.
      \u2022 5.4.4. Предоставление персональных данных Пользователей по запросу государственных органов осуществляется в порядке, предусмотренном законодательством.
  \u2022 5.5. Уничтожение персональных данных.
      \u2022 5.5.1. Персональные данные пользователя уничтожаются:
\u2022 - Администрацией Сайта после получения запроса от Пользователя на удаление данных со своей персональной страницы;
\u2022 - при удалении Администрацией Сайта информации, размещаемой Пользователем, а также персональной страницы Пользователя в случаях, установленных договором купли продажи (оферта).
6. Меры по защите информации о пользователях
  \u2022 6.1. Администрация Сайта принимает все возможные технические и организационно-правовые меры для обеспечения защиты персональных данных Пользователей от любых неправомерных действий.
  \u2022 6.2. Передача данных от браузера Пользователя к серверу Сайта производится с использованием 256-ти битного шифрования.
7. Ограничения
  \u2022 7.1. Действие настоящей Политики не распространяется на действия интернет-ресурсов третьих лиц.
  \u2022 7.2. Администрация Сайта не несет ответственности за действия третьих лиц, получивших в результате использования Интернета или Услуг Сайта доступ к информации о Пользователе и за последствия использования информации.
8. Обращения пользователей
  \u2022 8.1. Пользователи вправе направлять Администрации Сайта свои запросы, в том числе запросы относительно использования их персональных данных в письменной форме по электронному адресу: info@ffin.kz
  \u2022 8.2. Запрос, направляемый Пользователем, должен соответствовать требованиям подачи обращений в Службу сервиса и поддержки, а именно содержать:
      \u2022 - Ф.И.О.
      \u2022 - сведения, подтверждающие участие пользователя в отношениях с Администрацией (в частности, адрес электронной почты, который был использован для регистрации на сайте);
      \u2022 - номер телефона.
Администрация Сайта обязуется рассмотреть и направить ответ на поступивший запрос Пользователя в течение 10 календарных дней с момента поступления обращения.
        `}
    </p>
  )
}
