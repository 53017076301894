import cl from 'classnames'
import useAppContext from 'app/store/useAppContext'
import { SubmitAffirmationModal } from 'features/affirmation'
import TreeImg from 'shared/assets/images/protect-money/TreeImg.webp'
import cls from './ProtectMoney.module.scss'

export const ProtectMoney = () => {
  const {
    store: { isMobile500 },
  } = useAppContext()

  return (
    <section className={cl('flex__col_center', cls.page)}>
      <div className={cl(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <div className={cls.card}>
            <h3 className={cls.card__title}>
              Доступный вход и защита от падения курса
            </h3>

            <p className={cls.card__desc}>
              Для начала торговли ценными бумагами Вам не требуется много денег.
              До конца месяца Вы можете открыть депозит всего от $150.
            </p>

            <p className={cls.card__desc}>
              На Вашем счету будут только долларовые активы, и в случае падения
              курса в вашей стране Вы ничего не потеряете.
            </p>
          </div>

          <SubmitAffirmationModal
            btnContent={isMobile500 ? 'Открыть счет' : 'Оставить заявку'}
            btnProps={{ variant: 'default' }}
          />
        </div>

        <div className={cls.right_block}>
          <img src={TreeImg} alt="TreeImg" />
        </div>
      </div>
    </section>
  )
}
