import { PropsWithChildren } from 'react'

import classNames from 'classnames'

import styles from './Layout.module.scss'

interface ILayoutProps extends PropsWithChildren {
  className?: string
}

export default function Layout({ children, className }: ILayoutProps) {
  return (
    <div className={`${styles.layout} ${className ?? ''}`}>
      <div
        className={classNames(
          'container__desktop',
          'flex__row_center',
          styles.container,
        )}
      >
        {children}
      </div>
    </div>
  )
}
