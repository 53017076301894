import { Dispatch, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import cl from 'classnames'

import type { IAction } from 'app/store/model/action-types'
import type { IUser } from 'entities/user'
import {
  setIsAffirmationModal,
  setUserAccepted,
  setUserInfo,
} from 'app/store/actions'
import { ROUTES } from 'shared/config/routes'
import { Button } from 'shared/ui/button'

import type { IFormInputs } from '../../model/types/IFormInputs'
import { sendUserData } from '../../api/sendUserData'
import { getApiFormData } from '../../lib/helpers/getApiFormData'
import { getFormSchema } from '../../lib/helpers/getFormSchema'
import { AcceptModal } from '../AcceptModal/AcceptModal'
import { SelectCallHours } from '../SelectCallHours/SelectCallHours'
import { InputPhone } from '../InputPhone/InputPhone'
import { InputsHidden } from '../InputsHidden/InputsHidden'
import { CheckboxPolitics } from 'features/affirmation/ui/CheckboxPolitics/CheckboxPolitics'
import { CheckboxAge } from 'features/affirmation/ui/CheckboxAge/CheckboxAge'
import { InputsName } from '../InputsName/InputsName'
import { InputEmail } from '../InputEmail/InputEmail'
import { CheckboxesMessengers } from '../CheckboxesMessengers/CheckboxesMessengers'
import cls from './AffirmationForm.module.scss'

interface AffirmationFormProps {
  user: IUser
  dispatch: Dispatch<IAction>
  title?: string
  description?: string
  className?: string
  isAffirmationModal?: boolean
}

export function AffirmationForm(props: AffirmationFormProps) {
  const { user, dispatch, title, description, isAffirmationModal, className } =
    props

  const navigate = useNavigate()
  const location = useLocation()

  const modalId = isAffirmationModal ? '-modal' : ''

  const [country, setCountry] = useState<string>(user?.api?.country)

  const apiFormData = getApiFormData(country)
  const formSchema = getFormSchema({
    phoneMatchesReg: apiFormData?.phoneMatchesReg,
    phoneMatchesErr: apiFormData?.phoneMatchesErr,
  })
  const formMethods = useForm<IFormInputs>({
    defaultValues: {
      phoneNumber: '',
      isPolitics: user.accepted.isPolitics ?? true,
      isAge: user.accepted.isAge ?? true,
      whatsapp: user.accepted.whatsapp ?? true,
      telegram: user.accepted.telegram ?? false,
      viber: user.accepted.viber ?? false,
      country: user.api.country,
      ip: user.api.ip,
      external_id: user.searchParams.external_id,
      metric: user.searchParams.metric,
      gaid: user.searchParams.gaid,
      sub1: user.searchParams.sub1,
      sub2: user.searchParams.sub2,
      sub3: user.searchParams.sub3,
      sub4: user.searchParams.sub4,
      sub5: user.searchParams.sub5,
      sub6: user.searchParams.sub6,
      sub7: user.searchParams.sub7,
      apps_id: user.searchParams.apps_id,
      devKey: user.searchParams.devKey,
      bundle: user.searchParams.bundle,
      status: user.searchParams.status,
    },
    // @ts-ignore
    resolver: yupResolver(formSchema),
  })
  const {
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = formMethods

  const closeAffirmationModal = () => {
    dispatch(setIsAffirmationModal(false))
  }
  const onChecked = (e: any, id: any) => {
    const value = e?.target?.checked

    setValue(id, value)

    dispatch(setUserAccepted({ [id]: value }))
  }
  const onSubmit = handleSubmit(async (data: any) => {
    // query params
    setValue('external_id', user.searchParams.external_id)
    setValue('metric', user.searchParams.metric)
    setValue('gaid', user.searchParams.gaid)
    setValue('sub1', user.searchParams.sub1)
    setValue('sub2', user.searchParams.sub2)
    setValue('sub3', user.searchParams.sub3)
    setValue('sub4', user.searchParams.sub4)
    setValue('sub5', user.searchParams.sub5)
    setValue('sub6', user.searchParams.sub6)
    setValue('sub7', user.searchParams.sub7)
    setValue('apps_id', user.searchParams.apps_id)
    setValue('devKey', user.searchParams.devKey)
    setValue('bundle', user.searchParams.bundle)
    setValue('status', user.searchParams.status)

    setValue('isPolitics', user.accepted.isPolitics)
    setValue('isAge', user.accepted.isAge)
    setValue('whatsapp', user.accepted.whatsapp)
    setValue('telegram', user.accepted.telegram)
    setValue('viber', user.accepted.viber)

    await sendUserData({
      ...data,
      country: user.api.country,
      ip: user.api.ip,
    })

    dispatch(
      setUserInfo({
        firstName: data?.firstName,
        lastName: data?.lastName,
        phoneNumber: data?.phoneNumber,
        email: data?.email,
        callHours: data?.callHours,
      }),
    )

    closeAffirmationModal()

    navigate({
      pathname: ROUTES.REGISTERED,
      search: location.search,
    })
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      dispatch(
        setUserAccepted({
          isPolitics: true,
          isAge: true,
          whatsapp: true,
          telegram: false,
          viber: false,
        }),
      )

      reset()
    }
  }, [isSubmitSuccessful, reset])

  useEffect(() => {
    setCountry(user?.api?.country)
  }, [user?.api?.country])

  return (
    <FormProvider {...formMethods}>
      <form
        // @ts-ignore
        onSubmit={handleSubmit(onSubmit)}
        className={cl(cls.form, className)}
      >
        <h3 className={cls.form__title}>{title}</h3>

        {description && <p className={cls.form__desc}>{description}</p>}

        <InputsName modalId={modalId} />

        <InputPhone
          modalId={modalId}
          apiFormData={apiFormData}
          setCountry={setCountry}
        />

        <InputEmail modalId={modalId} />

        <SelectCallHours setValue={setValue} />

        <InputsHidden modalId={modalId} />

        <Button type="submit">Оставить заявку</Button>

        <CheckboxPolitics
          user={user}
          onChecked={onChecked}
          dispatch={dispatch}
        />

        <CheckboxAge user={user} onChecked={onChecked} />

        <CheckboxesMessengers
          user={user}
          modalId={modalId}
          onChecked={onChecked}
        />

        <AcceptModal />
      </form>
    </FormProvider>
  )
}
