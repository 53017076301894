import { ConnectForm } from '../ConnectForm/ConnectForm'
import cls from '../AffirmationForm/AffirmationForm.module.scss'

type InputsNameProps = {
  modalId?: string
}

export const InputsName = (props: InputsNameProps) => {
  const { modalId } = props

  return (
    <ConnectForm>
      {({
        // @ts-ignore
        register,
        // @ts-ignore
        formState: { errors },
      }) => (
        <div className={cls.form__names}>
          <div className={cls.form__input_block}>
            <input
              {...register('firstName')}
              id={'firstName' + modalId}
              name={'firstName'}
              placeholder="Имя"
              type="text"
            />
            {errors?.firstName && (
              <p className={cls.errors}>{errors.firstName.message}</p>
            )}
          </div>

          <div className={cls.form__input_block}>
            <input
              {...register('lastName')}
              id={'lastName' + modalId}
              name={'lastName'}
              placeholder="Фамилия"
              type="text"
            />
            {errors?.lastName && (
              <p className={cls.errors}>{errors.lastName.message}</p>
            )}
          </div>
        </div>
      )}
    </ConnectForm>
  )
}
