import { ConnectForm } from '../ConnectForm/ConnectForm'
import cls from '../AffirmationForm/AffirmationForm.module.scss'

type InputEmailProps = {
  modalId?: string
}

export const InputEmail = (props: InputEmailProps) => {
  const { modalId } = props

  return (
    <ConnectForm>
      {({
        // @ts-ignore
        register,
        // @ts-ignore
        formState: { errors },
      }) => (
        <div className={cls.form__input_block}>
          <input
            {...register('email')}
            id={'email' + modalId}
            name={'email'}
            placeholder="Email"
            type="text"
          />
          {errors?.email && (
            <p className={cls.errors}>{errors.email.message}</p>
          )}
        </div>
      )}
    </ConnectForm>
  )
}
